"use client";

import React from "react";
import "./style.css";
import useMobile from "@/lib/hooks/UseMobile";
import SceneComp from "./SceneSurf/SceneSurf";
import { motion } from "framer-motion";

const Services = () => {
  const isMobile = useMobile();

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3,
        delayChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 30 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        damping: 12,
        stiffness: 100,
      },
    },
  };

  const titleVariants = {
    hidden: { opacity: 0, x: -20 },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        type: "spring",
        damping: 10,
        stiffness: 100,
      },
    },
  };

  const textVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.5,
        delay: 0.2,
      },
    },
  };

  const sceneVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.8,
        ease: "easeOut",
      },
    },
  };

  return (
    <motion.div
      className="container-services z-[10]"
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, amount: 0.1 }}
      variants={containerVariants}
    >
      <motion.div className="box-services-items" variants={containerVariants}>
        <motion.div className="container-text-services" variants={itemVariants}>
          <motion.h2
            className="title-service text-white uppercase font-black"
            variants={titleVariants}
            whileHover={{ scale: 1.03, transition: { duration: 0.2 } }}
          >
            Tomá Tus clases
          </motion.h2>
          <motion.p className="text-service" variants={textVariants}>
            ¿Todavía dudás en cómo iniciarte en el mundo de los deportes
            acuáticos? En Surfway tendrás a tu disposición a las mejores
            escuelas e instructores. Para tu mayor seguridad, antes de contratar
            el tuyo podrás ver sus calificaciones, reseñas y ¡muchos aspectos
            más!
          </motion.p>
        </motion.div>
        <motion.div className="container-text-services" variants={itemVariants}>
          <motion.h2
            className="title-service text-white uppercase font-black"
            variants={titleVariants}
            whileHover={{ scale: 1.03, transition: { duration: 0.2 } }}
          >
            Enseñá
          </motion.h2>
          <motion.p className="text-service" variants={textVariants}>
            ¿Tenés una escuela y te gustaría acceder a un mayor número de
            clientes de todo el mundo? ¡Armá tu perfil de Escuela y empezá ya!
          </motion.p>
        </motion.div>

        <motion.div className="container-text-services" variants={itemVariants}>
          <motion.h2
            className="title-service text-white uppercase font-black"
            variants={titleVariants}
            whileHover={{ scale: 1.03, transition: { duration: 0.2 } }}
          >
            Conocé los mejores spots de tu deporte favorito
          </motion.h2>
          <motion.p className="text-service" variants={textVariants}>
            ¡Donde quieras que estés, si hay agua es posible que haya un lugar
            especial que te está esperando! Ahora, con Surfway no tenés porqué
            perderte de nada: podrás buscar los distintos spots por
            país, ciudad, etc.
          </motion.p>
        </motion.div>
      </motion.div>
      {!isMobile && (
        <div
          className="w-[20rem] h-[55rem]"
        >
          <SceneComp />
        </div>
      )}
      <motion.div className="box-services-items" variants={containerVariants}>
        <motion.div className="container-text-services" variants={itemVariants}>
          <motion.h2
            className="title-service text-white uppercase font-black"
            variants={titleVariants}
            whileHover={{ scale: 1.03, transition: { duration: 0.2 } }}
          >
            diseñá tu propia aventura
          </motion.h2>
          <motion.p className="text-service" variants={textVariants}>
            Comenza tu aventura en los deportes acuáticos de la mano de las mejores escuelas.
          </motion.p>
        </motion.div>

        <motion.div className="container-text-services" variants={itemVariants}>
          <motion.h2
            className="title-service text-white uppercase font-black"
            variants={titleVariants}
            whileHover={{ scale: 1.03, transition: { duration: 0.2 } }}
          >
            Compartí tu experiencia y ganá
          </motion.h2>
          <motion.p className="text-service" variants={textVariants}>
            Luego de finalizar la clase, comentá cómo fue tu experiencia (y puntuá a tu escuela o instructor), y automáticamente estás participando en nuestros sorteos por muchos premios. Aumentás tus chances si nos arrobás en Instagram.
          </motion.p>
        </motion.div>
      </motion.div>
    </motion.div>
  );
};

export default Services;
