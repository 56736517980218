"use client";

import { useEffect, useState } from "react";
import "./style.css";
import Button from "../../components/Button/Button";
import useMobile from "@/lib/hooks/UseMobile";
import { obtenerLocalidades } from "@/app/api/controllers/localidades";
import { obtenerPaises } from "@/app/api/controllers/paises";
import { deportesAll } from "@/app/api/controllers/deportes";
import { useRouter } from "next/navigation";
import { motion } from "framer-motion";

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

const Hero = () => {
  const router = useRouter();
  const [country, setCountry] = useState<string>("");
  const [countryId, setCountryId] = useState<number>(9); // Default Argentina
  const [sport, setSport] = useState<string>("");
  const [location, setLocation] = useState<string>("");
  const [deportes, setDeportes] = useState<any[]>([]);
  const [paises, setPaises] = useState<any[]>([]);
  const [localidades, setLocalidades] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  // Función para obtener los países
  const fetchPaises = async () => {
    try {
      const data = await obtenerPaises();
      setPaises(data);
    } catch (err) {
      console.error("Error al obtener países:", err);
    }
  };

  // Función para obtener las localidades según el país seleccionado
  const fetchLocalidades = async (paisId: number) => {
    try {
      setLoading(true); // Activar estado de carga
      const data = await obtenerLocalidades(paisId);
      setLocalidades(data);
    } catch (err) {
      console.error("Error al obtener localidades:", err);
    } finally {
      setLoading(false); // Desactivar estado de carga
    }
  };

  // Función para obtener todos los deportes
  const fetchDeportes = async () => {
    try {
      const data = await deportesAll();
      setDeportes(data);
    } catch (err) {
      console.error("Error al obtener deportes:", err);
    }
  };

  // Obtener países y deportes al montar el componente
  useEffect(() => {
    const fetchInitialData = async () => {
      await Promise.all([fetchPaises(), fetchDeportes()]);
    };
    fetchInitialData();
  }, []);

  // Obtener localidades cada vez que cambie el countryId
  useEffect(() => {
    setLocalidades([]); // Limpiar localidades antes de cargar las nuevas
    fetchLocalidades(countryId);
  }, [countryId]);

  // Manejar búsqueda con parámetros seleccionados
  const handleSearch = () => {
    const searchParams = new URLSearchParams();
    if (country) searchParams.append("country", country);
    if (sport) searchParams.append("sport", sport);
    if (location) searchParams.append("location", location);

    router.push(`/clases?${searchParams.toString()}`);
  };

  const isMobile = useMobile();

  return (
    <div className="container_home">
      <motion.svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1440 130"
        className="wave"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        <path
          fill="#f6fbff"
          fillOpacity="1"
          d="M0,96L80,85.3C160,75,320,53,480,58.7C640,64,800,96,960,96C1120,96,1280,64,1360,48L1440,32L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
        ></path>
      </motion.svg>
      <div className="flex justify-between items-center w-full  px-4 sm:px-8">
        <div
          className={`flex justify-center relative w-full ${isMobile
              ? "items-center flex-nowrap mt-6 md:mt-0 "
              : "items-start ml-[3.5rem] mt-4 "
            } min-w-[50vw] px-3 gap-[1rem] 2xl:gap-[2rem] flex-col`}
        >
          <motion.div 
            className="w-full z-[9999] relative"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <div
              className={`box-text-hero flex justify-center flex-col w-full items-start text-white uppercase font-bold`}
            >
              <motion.h1 
                className={`text-hero`}
                initial={{ opacity: 0, x: -30 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5, delay: 0.2 }}
              >
                Subite a
              </motion.h1>
              <motion.h2 
                className={`text-wave`}
                initial={{ opacity: 0, x: 0 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5, delay: 0.4 }}
              >
                #laNuevaOla
              </motion.h2>
            </div>

            <div
              className={`box-text-subHero flex gap-0 mt-6 justify-center flex-col w-full text-[2.5rem] tracking-[1.2px] items-start text-[#ffffff] uppercase font-bold`}
            >
              <motion.h3 
                className={`text-subHero`}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.6 }}
              >
                Aprendé Deportes
              </motion.h3>
              <div className="flex justify-center items-center gap-4">
                <motion.h3 
                  className={`text-subHero`}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: 0.8 }}
                >
                  Acuáticos
                </motion.h3>
                <motion.div 
                  className="line"
                  initial={{ width: 0 }}
                  animate={{ width: "100%" }}
                  transition={{ duration: 0.8, delay: 1 }}
                ></motion.div>
              </div>
            </div>
          </motion.div>
          <motion.div 
            className="flex justify-start items-start flex-col w-full !z-[9999] relative"
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 1.2 }}
          >
            <div className="relative flex justify-start lg:justify-center items-start flex-col w-full lg:max-w-[30rem] 2xl:max-w-[35rem] leading-[2.4rem] text-[#23629C] gap-[.7rem] z-[999] md:pr-10 lg:pr-0">
              <div className="w-full lg:max-w-xl mx-auto space-y-4 p-4 px-0 rounded-lg relative !z-[999]">
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.4, delay: 1.4 }}
                >
                  <Select onValueChange={(value) => setSport(value)}>
                    <SelectTrigger className="w-full bg-[#E8EDF4] border-none text-[#1B62BF] h-[2.5rem] 2xl:h-[2.8rem]">
                      <SelectValue placeholder="Deportes" />
                    </SelectTrigger>
                    <SelectContent>
                      {deportes.map((deporte) => (
                        <SelectItem
                          key={deporte.deporteId}
                          value={deporte.deporteId.toString()}
                        >
                          {deporte.nombre}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </motion.div>

                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.4, delay: 1.6 }}
                >
                  <Select
                    name="country"
                    onValueChange={(value) => {
                      const selectedPais = paises.find(
                        (p) => p.id.toString() === value
                      );
                      if (selectedPais) {
                        setCountryId(selectedPais.id);
                        setCountry(value);
                      }
                    }}
                    value={country}
                  >
                    <SelectTrigger className="w-full bg-[#E8EDF4] border-none text-[#1B62BF] h-[2.4rem] 2xl:h-[2.8rem]">
                      <SelectValue placeholder="País" />
                    </SelectTrigger>
                    <SelectContent>
                      {paises.map((pais) => (
                        <SelectItem key={pais.id} value={pais.id.toString()}>
                          {pais.nombrePais}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </motion.div>

                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.4, delay: 1.8 }}
                >
                  <Select
                    onValueChange={(value) => setLocation(value)}
                    disabled={loading}
                  >
                    <SelectTrigger className="w-full bg-[#E8EDF4] border-none text-[#1B62BF] h-[2.5rem] 2xl:h-[2.8rem]">
                      <SelectValue placeholder="Localidad" />
                    </SelectTrigger>
                    <SelectContent>
                      {localidades.map((local) => (
                        <SelectItem
                          key={local.localidadId}
                          value={local.localidadId.toString()}
                        >
                          {local.nombreLocalidad}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </motion.div>
              </div>

              <motion.div
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5, delay: 2 }}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <Button
                  title="BUSCAR"
                  className="w-full text-center flex justify-center items-center rounded-xl h-[2.8rem] 2xl:h-[3rem] "
                  onClick={handleSearch}
                  disabled={loading}
                />
              </motion.div>
            </div>
          </motion.div>
        </div>
        {!isMobile && (
          <motion.div 
            className="box"
            initial={{ opacity: 0, scale: 0.8, rotate: -10 }}
            animate={{ opacity: 1, scale: 1, rotate: 0 }}
            transition={{ duration: 0.8, delay: 0.5, type: "spring" }}
          >
            <div className="spin-container">
              <div className="shape">
                <div className="bd"></div>
              </div>
            </div>
          </motion.div>
        )}
      </div>
    </div>
  );
};

export default Hero;