import React from "react";

interface Props {
  title: string;
  className?: string;
  variant?: "sub" | "default";
  onClick?: () => void;
  disabled?: boolean;
}

const Button = ({ title, className, variant = "default", onClick }: Props) => {
  const subStyles =
    "bg-gradient-to-tr from-blue-100/0 via-blue-100/0 to-blue-100/0 ring-2 ring-blue-300/80";
  const defaultStyles =
    "bg-gradient-to-tr from-blue-500 via-blue-500 to-blue-300 ";

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <button
      onClick={handleClick}
      className={`relative inline-flex items-center gap-2 px-6 py-2 font-semibold text-blue-50 rounded-full overflow-hidden hover:opacity-90 transition-opacity before:absolute before:top-4 before:left-1/2 before:-translate-x-1/2 before:w-[100px] before:h-[100px] before:rounded-full before:bg-gradient-to-b before:from-blue-50/10 before:blur-xl cursor-pointer shadow-lg ${
        variant === "sub" ? subStyles : defaultStyles
      } ${className}`}
    >
      {title}
    </button>
  );
};

export default Button;
