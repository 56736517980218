"use client";

import { useState } from "react";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Textarea } from "@/components/ui/textarea";
import Marquee from "react-fast-marquee";
import axios from "axios";
import { toast } from "react-toastify";
import { motion } from "framer-motion";

export default function Contact() {
  const [formData, setFormData] = useState({
    nombre: "",
    email: "",
    telefono: "",
    mensaje: "",
  });

  const [errors, setErrors] = useState({
    nombre: false,
    email: false,
    telefono: false,
    mensaje: false,
  });

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateEmail = (email: string) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const newErrors = {
      nombre: formData.nombre === "",
      email: !validateEmail(formData.email),
      telefono: formData.telefono === "",
      mensaje: formData.mensaje === "",
    };
    setErrors(newErrors);

    if (!Object.values(newErrors).includes(true)) {
      try {
        const response = await axios.post(
          `${process.env.NEXT_PUBLIC_API_URL}/public/enviarMensajeContacto`,
          {
            nombre: formData.nombre,
            email: formData.email,
            telefono: formData.telefono,
            mensaje: formData.mensaje,
          }
        );

        if (response.status === 200) {
          toast.success("Mensaje enviado exitosamente");
          setFormData({
            nombre: "",
            email: "",
            telefono: "",
            mensaje: "",
          });
        }
      } catch (error) {
        console.error("Error al enviar el mensaje:", error);
        toast.error(
          "Hubo un error al enviar el mensaje. Por favor intente nuevamente."
        );
      }
    }
  };

  const logoRepeat = [
    {
      logo: "/images/logo/Logo_blanco.webp",
    },
    {
      logo: "/images/logo/Logo.webp",
    },
    {
      logo: "/images/logo/Logo_blanco.webp",
    },
    {
      logo: "/images/logo/Logo.webp",
    },
    {
      logo: "/images/logo/Logo_blanco.webp",
    },
    {
      logo: "/images/logo/Logo.webp",
    },
    {
      logo: "/images/logo/Logo_blanco.webp",
    },
    {
      logo: "/images/logo/Logo.webp",
    },
  ];

  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: { type: "spring", stiffness: 300, damping: 24 }
    }
  };

  const buttonVariants = {
    rest: { scale: 1 },
    hover: { 
      scale: 1.05,
      transition: { 
        type: "spring", 
        stiffness: 400, 
        damping: 10 
      } 
    },
    tap: { scale: 0.95 }
  };

  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="min-h-screen flex items-center justify-center flex-col overflow-hidden relative mb-[7rem] text-white z-[9999]"
    >
      <motion.div 
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.7 }}
        className="box-marquee w-full flex justify-between items-center text-[2rem] my-[6rem]"
      >
        <Marquee className="w-full flex justify-between items-center gap-10 bg-[#3168af7e] py-4">
          {logoRepeat.map((data, index) => {
            return (
              <motion.div
                key={index}
                whileHover={{ scale: 1.1 }}
                transition={{ type: "spring", stiffness: 400, damping: 10 }}
                className="w-full flex justify-between items-center gap-10"
              >
                <img
                  src={data.logo}
                  alt="logo"
                  className="objet-cover w-[17rem] px-[4rem]"
                />
              </motion.div>
            );
          })}
        </Marquee>
      </motion.div>

      <motion.form
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        onSubmit={handleSubmit}
        className="space-y-4 w-full max-w-3xl p-8 rounded-lg"
      >
        <motion.h1 
          variants={itemVariants}
          className="text-[3rem] 2md:text-[4rem] font-black text-center mb-4"
        >
          CONTACTO
        </motion.h1>
        <motion.p 
          variants={itemVariants}
          className="text-center mb-6 text-lg"
        >
          Estamos a tu disposición para atender cualquier consulta, comentario o
          solicitud que puedas tener. Por favor, completa los siguientes campos
          con la información necesaria y nos pondremos en contacto contigo a la
          brevedad.
        </motion.p>
        <motion.div variants={itemVariants}>
          <Label htmlFor="nombre">
            Nombre <span className="text-red-500">*</span>
          </Label>
          <Input
            id="nombre"
            name="nombre"
            placeholder="Nombre"
            value={formData.nombre}
            onChange={handleChange}
            className={`mt-1 h-[3.3rem] bg-[#0a172e83] border-[#fefefe63] ${
              errors.nombre ? "border-red-500" : ""
            }`}
          />
        </motion.div>
        <motion.div variants={itemVariants}>
          <Label htmlFor="email">
            Email <span className="text-red-500">*</span>
          </Label>
          <Input
            id="email"
            name="email"
            type="email"
            placeholder="Email"
            value={formData.email}
            onChange={handleChange}
            className={`mt-1 h-[3.3rem] bg-[#0a172e83] border-[#fefefe63] ${
              errors.email ? "border-red-500" : ""
            }`}
          />
        </motion.div>
        <motion.div variants={itemVariants}>
          <Label htmlFor="telefono">
            Teléfono <span className="text-red-500">*</span>
          </Label>
          <Input
            id="telefono"
            name="telefono"
            placeholder="Teléfono"
            value={formData.telefono}
            onChange={handleChange}
            className={`mt-1 h-[3.3rem] bg-[#0a172e83] border-[#fefefe63] ${
              errors.telefono ? "border-red-500" : ""
            }`}
          />
        </motion.div>
        <motion.div variants={itemVariants}>
          <Label htmlFor="mensaje">
            Mensaje <span className="text-red-500">*</span>
          </Label>
          <Textarea
            id="mensaje"
            name="mensaje"
            placeholder="Ingrese su mensaje"
            value={formData.mensaje}
            onChange={handleChange}
            className={`mt-1 bg-[#0a172e83] border-[#fefefe63] ${
              errors.mensaje ? "border-red-500" : ""
            }`}
          />
          {errors.mensaje && (
            <p className="text-red-500 text-sm mt-1">
              El mensaje es requerido.
            </p>
          )}
        </motion.div>

        <motion.button
          type="submit"
          variants={buttonVariants}
          initial="rest"
          whileHover="hover"
          whileTap="tap"
          className={`relative !mt-7 inline-flex items-center gap-2 px-6 py-2 font-semibold text-blue-50 rounded-full overflow-hidden hover:opacity-90 transition-opacity before:absolute before:top-4 before:left-1/2 before:-translate-x-1/2 before:w-[100px] before:h-[100px] before:rounded-full before:bg-gradient-to-b before:from-blue-50/10 before:blur-xl bg-gradient-to-tr from-blue-100/0 via-blue-100/0 to-blue-100/0 ring-2 ring-blue-300/80 `}
        >
          ENVIAR MENSAJE
        </motion.button>
      </motion.form>
    </motion.div>
  );
}
