"use client";

import React, { Suspense, useRef, useEffect } from "react";
import { Canvas, useFrame  } from "@react-three/fiber";
import { useGLTF, OrbitControls } from "@react-three/drei";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import * as THREE from "three";

gsap.registerPlugin(ScrollTrigger);

const SurfScene = () => {
  const { scene } = useGLTF("/TablaDeSurf.glb");
  const surfRef = useRef<THREE.Group>(null!);
  const gsapAnimationActiveRef = useRef(true);

  useEffect(() => {
    const model = surfRef.current;

    if (model) {
      model.scale.set(1.18, 1.18, 1.18);
      model.position.set(0, 5, 0);

      let tl = gsap.timeline({
        scrollTrigger: {
          trigger: ".webGlScene",
          end: "15% 25% ",
          scrub: 3,
          onEnter: () => {
            gsapAnimationActiveRef.current = true;
          },
        },
      });

      tl.to(model.position, {
        y: -1.5,
        duration: 10,
      });

      tl.to(model.rotation, {
        y: 6.3,
        duration: 10,
        onComplete: () => {
          gsapAnimationActiveRef.current = false;
        },
      });
    }

    return () => {
      ScrollTrigger.killAll();
    };
  }, []);

  useFrame(({ clock }) => {
    const elapsedTime = clock.getElapsedTime();
    if (surfRef.current && !gsapAnimationActiveRef.current) {
      surfRef.current.rotation.y = Math.cos(elapsedTime) * 0.5;
    }
  });

  return <primitive ref={surfRef} object={scene} />;
};

export default function SceneComp(){
  return (
    <>
      <Canvas
        className="webGlScene"
        camera={{ position: [0, 0, 3], fov: 64 }}
        gl={{ antialias: true, alpha: true }}
      >
        <Suspense fallback={null}>
          <directionalLight position={[1, 2, -4]} intensity={1} />
          <directionalLight position={[0, 0, 2]} intensity={1.2} />
          <ambientLight intensity={3} />
          <SurfScene />
          <OrbitControls
            enableDamping
            enableZoom={false}
            enablePan={false}
            dampingFactor={0.04}
          />
        </Suspense>
      </Canvas>
    </>
  );
};

useGLTF.preload("/TablaDeSurf.glb");

 
