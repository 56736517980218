"use client";

import WaveBottom from "@/app/components/wave/WaveBottom";
import WaveTop from "@/app/components/wave/WaveTop";
import React, { useEffect } from "react";
import "./style.css";
import Image from "next/image";
import { motion } from "framer-motion";

const About = () => {
  // Variantes para animaciones
  const fadeInUp = {
    hidden: { opacity: 0, y: 30 },
    visible: { opacity: 1, y: 0 }
  };

  const staggerContainer = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2
      }
    }
  };

  return (
    <div className="w-full min-h-[100vh] bg-[#f6fbff] relative z-[10]">
      <div className="absolute hidden lg:block  top-[5rem] left-[2rem] w-[25rem] h-[25rem] bg-gradient-to-br from-blue-400/10 to-indigo-300/10 rounded-full pointer-events-none"></div>
      <div className="absolute hidden lg:block top-[20rem] right-[6rem] w-[35rem] h-[35rem] bg-gradient-to-br from-blue-400/10 to-indigo-300/10 rounded-full pointer-events-none"></div>
      <div className="relative h-full py-20 px-4 md:px-6">

        <div className="container mx-auto max-w-6xl">
          {/* Header Section with Logo and Title */}
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.3 }}
            variants={fadeInUp}
            transition={{ duration: 0.6 }}
            className="text-center mb-24"
          >
            <motion.div
              initial={{ scale: 0.8, opacity: 0 }}
              whileInView={{ scale: 1, opacity: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5 }}
              className="flex justify-center mb-8"
            >
              <Image
                src="/images/logo/Logo.webp"
                alt="Surfway Logo"
                width={160}
                height={160}
                className="transition-all duration-300 hover:scale-105"
              />
            </motion.div>
            <motion.h1
              variants={fadeInUp}
              className="text-3xl md:text-5xl font-bold text-gray-900 mb-6"
            >
              Descubrí tu pasión por los deportes acuáticos
            </motion.h1>
            <motion.div
              initial={{ width: 0 }}
              whileInView={{ width: "4rem" }}
              viewport={{ once: true }}
              transition={{ duration: 0.6, delay: 0.4 }}
              className="h-0.5 bg-blue-500 mx-auto rounded-full"
            />
          </motion.div>

          {/* Main Content */}
          <motion.div
            variants={staggerContainer}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.2 }}
            className="grid grid-cols-1 md:grid-cols-2 gap-16 lg:gap-24 mb-24"
          >
            {/* Left Content */}
            <motion.div
              variants={fadeInUp}
              transition={{ duration: 0.6 }}
              className="flex flex-col space-y-8"
            >
              <motion.div
                whileHover={{ scale: 1.02 }}
                transition={{ type: "spring", stiffness: 300 }}
                className="overflow-hidden rounded-lg shadow-sm"
              >
                <Image
                  src="/images/backgrounds/SurfSchool.png"
                  alt="Surfboards"
                  width={700}
                  height={400}
                  className="w-full h-auto object-cover transform transition-transform duration-700 hover:scale-105"
                />
              </motion.div>
              <motion.p
                variants={fadeInUp}
                className="text-gray-700 font-medium leading-relaxed text-lg"
              >
                Encontrá las mejores Escuelas e Instructores para aprender Surf,
                KiteSurf, WindSurf, StandUp Paddle, Bodyboarding y otros deportes
                acuáticos más.
              </motion.p>
            </motion.div>

            {/* Right Content */}
            <motion.div
              variants={fadeInUp}
              transition={{ duration: 0.6, delay: 0.2 }}
              className="flex flex-col space-y-8"
            >
              <motion.p
                variants={fadeInUp}
                className="text-gray-700 font-medium leading-relaxed text-lg"
              >
                Subite a{" "}
                <motion.span
                  whileHover={{ scale: 1.05 }}
                  className="text-blue-600 font-medium px-2 py-1 rounded-md"
                >
                  #LaNuevaOla
                </motion.span>{" "}
                de los deportes acuáticos de la mano de los mejores expertos y
                comenzá tu aventura teniendo las mejores experiencias. Solo tené
                en cuenta esto: es un camino de ida... ¡Después no digas
                que no te lo dijimos!
              </motion.p>
              <motion.div
                whileHover={{ scale: 1.02 }}
                transition={{ type: "spring", stiffness: 300 }}
                className="overflow-hidden rounded-lg shadow-sm"
              >
                <Image
                  src="/images/backgrounds/SurfHome.jpg"
                  alt="Beach with Surfboard"
                  width={700}
                  height={400}
                  className="w-full h-auto object-cover transform transition-transform duration-700 hover:scale-105"
                />
              </motion.div>
            </motion.div>
          </motion.div>

          {/* Experience Stats Section */}
          {/*        <motion.div 
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.3 }}
            variants={fadeInUp}
            transition={{ duration: 0.6 }}
            className="mb-24 py-12 px-6 bg-gray-50 rounded-2xl"
          >
            <motion.h2 
              variants={fadeInUp}
              className="text-2xl md:text-3xl font-semibold text-gray-900 mb-12 text-center"
            >
              Somos expertos en deportes acuáticos
            </motion.h2>
            <motion.div 
              variants={staggerContainer}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              className="grid grid-cols-1 md:grid-cols-3 gap-8"
            >
              <motion.div 
                variants={fadeInUp}
                whileHover={{ y: -5 }}
                className="flex flex-col items-center"
              >
                <motion.span 
                  initial={{ opacity: 0, scale: 0.5 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5 }}
                  className="text-5xl font-bold text-blue-600 mb-2"
                >
                  +500
                </motion.span>
                <motion.p variants={fadeInUp} className="text-gray-700">Estudiantes satisfechos</motion.p>
              </motion.div>
              <motion.div 
                variants={fadeInUp}
                whileHover={{ y: -5 }}
                className="flex flex-col items-center"
              >
                <motion.span 
                  initial={{ opacity: 0, scale: 0.5 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 0.2 }}
                  className="text-5xl font-bold text-blue-600 mb-2"
                >
                  25
                </motion.span>
                <motion.p variants={fadeInUp} className="text-gray-700">Instructores certificados</motion.p>
              </motion.div>
              <motion.div 
                variants={fadeInUp}
                whileHover={{ y: -5 }}
                className="flex flex-col items-center"
              >
                <motion.span 
                  initial={{ opacity: 0, scale: 0.5 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: 0.4 }}
                  className="text-5xl font-bold text-blue-600 mb-2"
                >
                  5
                </motion.span>
                <motion.p variants={fadeInUp} className="text-gray-700">Años de experiencia</motion.p>
              </motion.div>
            </motion.div>
          </motion.div> */}


          {/* Testimonials Section */}
          {/* <motion.div 
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.3 }}
            variants={fadeInUp}
            transition={{ duration: 0.6 }}
            className="mb-24 px-10"
          >
            <motion.h2 
              variants={fadeInUp}
              className="text-2xl md:text-3xl font-semibold text-gray-900 mb-12 text-center"
            >
              Lo que dicen nuestros alumnos
            </motion.h2>
            <motion.div 
              variants={staggerContainer}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              className="grid grid-cols-1 md:grid-cols-2 gap-8"
            >
              <motion.div 
                variants={fadeInUp}
                whileHover={{ y: -5, boxShadow: "0 10px 25px -5px rgba(59, 130, 246, 0.1)" }}
                transition={{ duration: 0.3 }}
                className="bg-blue-50 p-8 rounded-xl relative"
              >
                <svg className="absolute -top-4 -left-4 w-12 h-12 text-blue-300" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M14.017 21v-7.391c0-5.704 3.731-9.57 8.983-10.609l.995 2.151c-2.432.917-3.995 3.638-3.995 5.849h4v10h-9.983zm-14.017 0v-7.391c0-5.704 3.748-9.57 9-10.609l.996 2.151c-2.433.917-3.996 3.638-3.996 5.849h3.999v10h-9.999z" />
                </svg>
                <motion.p 
                  variants={fadeInUp}
                  className="text-gray-700 italic mb-6"
                >
                  El curso de surf fue una experiencia increíble. Los instructores son muy pacientes y realmente saben lo que hacen. ¡En solo una semana ya estaba parado en la tabla!
                </motion.p>
                <motion.div 
                  variants={fadeInUp}
                  className="flex items-center"
                >
                  <motion.div
                    whileHover={{ rotate: 5 }}
                    className="w-12 h-12 bg-blue-200 rounded-full flex items-center justify-center mr-4"
                  >
                    <span className="text-blue-600 font-bold">MG</span>
                  </motion.div>
                  <div>
                    <h4 className="font-medium">Martín González</h4>
                    <p className="text-sm text-gray-500">Alumno de Surf</p>
                  </div>
                </motion.div>
              </motion.div>
              
              <motion.div 
                variants={fadeInUp}
                whileHover={{ y: -5, boxShadow: "0 10px 25px -5px rgba(59, 130, 246, 0.1)" }}
                transition={{ duration: 0.3 }}
                className="bg-blue-50 p-8 rounded-xl relative"
              >
                <svg className="absolute -top-4 -left-4 w-12 h-12 text-blue-300" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M14.017 21v-7.391c0-5.704 3.731-9.57 8.983-10.609l.995 2.151c-2.432.917-3.995 3.638-3.995 5.849h4v10h-9.983zm-14.017 0v-7.391c0-5.704 3.748-9.57 9-10.609l.996 2.151c-2.433.917-3.996 3.638-3.996 5.849h3.999v10h-9.999z" />
                </svg>
                <motion.p 
                  variants={fadeInUp}
                  className="text-gray-700 italic mb-6"
                >
                  Las clases de kitesurf fueron una revelación para mí. Los profesionales de Surfway saben cómo enseñar desde cero, explicando cada detalle de manera clara. ¡Ya estoy planeando mi próxima aventura!
                </motion.p>
                <motion.div 
                  variants={fadeInUp}
                  className="flex items-center"
                >
                  <motion.div
                    whileHover={{ rotate: 5 }}
                    className="w-12 h-12 bg-blue-200 rounded-full flex items-center justify-center mr-4"
                  >
                    <span className="text-blue-600 font-bold">LR</span>
                  </motion.div>
                  <div>
                    <h4 className="font-medium">Laura Rodríguez</h4>
                    <p className="text-sm text-gray-500">Alumna de KiteSurf</p>
                  </div>
                </motion.div>
              </motion.div>
            </motion.div>
          </motion.div>
 */}
        </div>
      </div>

      <div className="relative w-full">
        <WaveTop
          color="#f6fbff"
          className="w-full absolute top-0 left-0 rotate-[180deg] !z-[99]"
        />
        <div className="banner-presentation rounded-t-3xl overflow-hidden">
          <div className="absolute inset-0 bg-gradient-to-b from-transparent to-[rgba(0,7,39,0.6)] z-20"></div>
          <div className="container mx-auto relative z-30 h-full flex items-center justify-center flex-col gap-10">
            <motion.h2
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.8 }}
              className="text-white text-3xl md:text-5xl font-bold text-center drop-shadow-md"
            >
              ¿Listo para comenzar tu aventura?
            </motion.h2>
            <motion.p
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.8, delay: 0.2 }}
              className="text-gray-200 text-sm leading-relaxed md:text-xl font-semibold text-center mb-8 max-w-3xl mx-auto"
            >
              Descubrí todo lo que los deportes acuáticos tienen para ofrecerte. ¡La mejor experiencia de tu vida te está esperando!
            </motion.p>
          </div>
        </div>
        <WaveBottom color="#000727" />
      </div>
    </div>
  );
};

export default About;
