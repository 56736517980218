import React from "react";
import "./style.css";

interface Props {
  color?: string;
  className?: string;
}

const WaveTop = ({ color = "white", className }: Props) => {
  return (
    <svg
      className={`editorial ${className}`}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 24 150 28"
      preserveAspectRatio="none"
    >
      <defs>
        <path
          id="gentle-wave"
          d="M-160 44c30 0 
                58-18 88-18s
                58 18 88 18 
                58-18 88-18 
                58 18 88 18
                v44h-352z"
        />
      </defs>
      <g className="parallax">
        <use xlinkHref="#gentle-wave" x="50" y="0" fill={color} />
      </g>
    </svg>
  );
};

export default WaveTop;
