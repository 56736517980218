/**
 * Configuración centralizada de la aplicación
 * Este archivo proporciona valores predeterminados para las variables de entorno
 * y asegura que siempre haya un valor disponible incluso si la variable de entorno no está definida.
 */

// API URL base
export const API_URL = process.env.NEXT_PUBLIC_API_URL || 'https://vps-3947371-x.dattaweb.com/api';

// Otras configuraciones
export const APP_MP_ID = process.env.NEXT_PUBLIC_APP_MP_ID || '4964059824667799';
export const APP_MP_URL_REDIRECT = process.env.NEXT_PUBLIC_APP_MP_URL_REDIRECT || 'https://www.surfwayapp.com/auth/mercadopago';
export const KEY_MP = process.env.NEXT_PUBLIC_KEY_MP || 'APP_USR-62c145b5-ed16-450c-ab9a-998d2c0822d8';




const config = {
  API_URL,
  APP_MP_ID,
  APP_MP_URL_REDIRECT,
  KEY_MP,
};

export default config; 