import axios from "axios";

const API_URL = process.env.NEXT_PUBLIC_API_URL as string;

export const obtenerLocalidades = async (id: number = 9) => { // 9 es el id de Argentina
  try {
    const response = await axios.get(`${API_URL}/localidades/pais/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error al obtener las localidades:", error);
    throw error;
  }
};
