import axios from "axios";
import { API_URL } from "@/config";

// Usar la configuración centralizada en lugar de process.env directamente
// const API_URL = process.env.NEXT_PUBLIC_API_URL as string;

export const deportesAll = async () => {
  try {
    const response = await axios.get(`${API_URL}/deportes/all`);
    return response.data;
  } catch (error) {
    console.error("Error al obtener deportes:", error);
    throw error;
  }
};

export const deportesImgAll = async () => {
  try {
    const response = await axios.get(`${API_URL}/deportes/imgs/all`);
    return response.data;
  } catch (error) {
    console.error("Error al obtener las imágenes de los deportes:", error);
    throw error;
  }
};
